/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/unbound-method */
import { useWishlist } from '@gemini-vsf/composables';
import { WishlistQueryVariables } from '@gemini-vsf/api-client/lib/types/GraphQL';
import { ComposableFunctionArgs } from '@vue-storefront/core/lib/src/types';
import { sharedRef } from '@vue-storefront/core';
import { useContext } from '@nuxtjs/composition-api';
import { useApi, useGtm, useUser } from '~/composables';
import { GeminiEntityPrefixes } from '~/types/enums';
import { UseUserWishlist, ItemsInWishlistResponse, WishlistItemsCountResponse } from '~/types/types';
import { Product } from '@gemini-vsf/api-client';
import cookieNameEnum from '~/enums/cookieNameEnum';

const ITEMS_IN_WISHLIST = `
  query ItemsInWishlist($wishlistUid: ID, $items: [String]) {
    itemsInWishlist(wishlistUid: $wishlistUid, items: $items) {
      wishlist_ids {
        item_uid
      }
      product_id
    }
  }
`;

const WISHLIST_ITEMS_COUNT = `
  query WishlistItemsCount($wishlistUid: ID) {
    customer {
      wishlist(uid: $wishlistUid) {
        items_count
      }
    }
  }
`;

const useUserWishlists = (): UseUserWishlist => {
  const { load: loadComposable, wishlist, addItem: addItemComposable, removeItem: removeItemComposable } = useWishlist();
  const { query } = useApi();
  const { addToWishlistPush } = useGtm();
  const { isAuthenticated } = useUser();

  const {
    app: {
      $vsf: {
        $gemini: {
          config: {
            state: { setWishlist, getWishlist },
          },
        },
      },
      $cookies: appCookies,
    },
  } = useContext();

  const itemsInWishlist = sharedRef<Record<string, boolean>>({}, `useUserWishlists-itemsInWishlist`);
  const numberOfItemsInWishlist = sharedRef<number>(0, `useUserWishlists-numberOfItemsInWishlist`);
  const currentWishlistUid = sharedRef<string>(getWishlist(), `useUserWishlists-currentWishlistUid`);
  const loading = sharedRef<boolean>(false, `useUserWishlists-loading`);

  const resetWishlist = () => {
    setWishlist(null);
    itemsInWishlist.value = {};
    numberOfItemsInWishlist.value = 0;
    currentWishlistUid.value = null;
  };

  const isNotLoggedIn = (): boolean => {
    if (!appCookies.get(cookieNameEnum.customerCookieName)) {
      resetWishlist();
      return true;
    }
    return false;
  };

  const loadNumberOfItemsInWishlist = async () => {
    const wishlistUid = getWishlist();
    if (isNotLoggedIn() || !wishlistUid) return;
    const wishlistResponse: WishlistItemsCountResponse = await query(WISHLIST_ITEMS_COUNT, {
      wishlistUid: getWishlist(),
    });
    numberOfItemsInWishlist.value = wishlistResponse?.data?.customer?.wishlist?.items_count || 0;
  };

  // items is an array of product_grn
  const loadItemsFromList = async (items: string[]) => {
    const wishlistUid = getWishlist();
    if (isNotLoggedIn() || !wishlistUid) return;
    const itemsInWishlistResponse: ItemsInWishlistResponse = await query(ITEMS_IN_WISHLIST, {
      wishlistUid,
      items,
    });
    const tempObj = {};
    itemsInWishlistResponse?.data?.itemsInWishlist?.forEach((itemInWIshlist) => {
      const productId = itemInWIshlist.product_id;
      itemInWIshlist.wishlist_ids.forEach((item) => {
        tempObj[productId] = item.item_uid;
      });
    });
    itemsInWishlist.value = {
      ...itemsInWishlist.value,
      [wishlistUid]: {
        ...itemsInWishlist.value[wishlistUid],
        ...tempObj,
      },
    };
  };

  const loadWishlist = async (params?: ComposableFunctionArgs<WishlistQueryVariables>) => {
    if (isNotLoggedIn()) return;
    loading.value = true;
    await loadComposable({
      ...params,
      pageSize: 200,
      customQuery: {
        ...(appCookies.get(cookieNameEnum.customerCookieName) ? { customerWishlist: 'customerWishlistCustom' } : { wishlist: 'wishlistCustom' }),
      },
    });
    if (wishlist?.value?.items_count > 0) {
      numberOfItemsInWishlist.value = wishlist.value.items_count;
      await loadItemsFromList(wishlist.value.items.items.map((item) => item.item_grn));
    }
    loading.value = false;
  };

  const addItemToWishlist = async ({ product, quantity = 1 }: { product: Product; quantity?: number }) => {
    if (isNotLoggedIn()) return;
    loading.value = true;
    await addItemComposable({
      product,
      quantity,
      customQuery: {
        addItemToWishlist: 'addItemToWishlistCustom',
      },
    });
    addToWishlistPush(product);
    await loadWishlist();
    const wishlistUid = getWishlist();
    currentWishlistUid.value = wishlistUid;
    const wishlistItemUid = wishlist.value.items.items.find((item) => item.item_grn === product.sku).uid;
    if (!itemsInWishlist.value[wishlistUid]) itemsInWishlist.value[wishlistUid] = {};
    itemsInWishlist.value[wishlistUid || currentWishlistUid.value][
      product.sku
    ] = `${GeminiEntityPrefixes.WishlistItem}${wishlistUid}${wishlistItemUid}`;
    numberOfItemsInWishlist.value = wishlist?.value?.items?.items?.length || Number.parseInt(numberOfItemsInWishlist.value, 10) + 1;
    await loadItemsFromList(Object.keys(itemsInWishlist.value[wishlistUid]));
    loading.value = false;
  };

  const removeItemFromWishlist = async ({ product }: { product: Product }) => {
    if (isNotLoggedIn()) return;
    loading.value = true;
    const wishlistUid = getWishlist() || currentWishlistUid.value;
    await removeItemComposable({
      product,
      customQuery: {
        removeItemFromWishlist: 'removeItemFromWishlistCustom',
      },
    });
    delete itemsInWishlist?.value?.[wishlistUid][product?.sku];
    await loadWishlist();
    await loadItemsFromList(Object.keys(itemsInWishlist.value[wishlistUid]));
    await loadNumberOfItemsInWishlist();
    loading.value = false;
  };

  return {
    loadWishlist,
    wishlist,
    addItemToWishlist,
    loadItemsFromList,
    removeItemFromWishlist,
    itemsInWishlist,
    loading,
    numberOfItemsInWishlist,
    loadNumberOfItemsInWishlist,
    currentWishlistUid,
    isAuthenticated,
    resetWishlist,
  };
};

export default useUserWishlists;

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { UiNotification } from '~/composables/useUiNotification';

export const notifySuccess = (message: string, sendNotification: (notification: UiNotification) => void) => {
  sendNotification({
    id: Symbol('wishlist_generic_success'),
    message,
    type: 'success',
    icon: 'check',
    persist: false,
    title: 'Success',
  });
};

import gql from 'graphql-tag';

export const LOAD_WISHLIST_GQL = gql`
  query LoadWishlist($wishlistUid: ID!, $pageSize: Int, $nextPageToken: String) {
    wishlist(wishlistUid: $wishlistUid) {
      items_count
      items(pageSize: $pageSize, nextPageToken: $nextPageToken) {
        next_page_token
        page_size
        items {
          uid
          wishlist_uid
          qty
          item_grn
          product {
            sku
            vsf_typename
            merchant_sku
            variants {
              option_uids
              product {
                uid
                merchant_sku
                grn
                sku
                vsf_typename
                thumbnail {
                  uid
                  url
                }
                price_range {
                  minimum_price {
                    final_price {
                      value
                    }
                    regular_price {
                      value
                    }
                  }
                }
              }
            }
            configurable_options {
              values {
                label
                swatch_data {
                  ... on ImageSwatchData {
                    thumbnail {
                      url
                      uid
                    }
                  }
                }
                uid
              }
              label
              attribute_uid
              attribute_code
              uid
            }
            uid
            grn
            urls {
              linkRel
              urlPath
            }
            name
            thumbnail {
              url
              uid
            }
            price_range {
              minimum_price {
                final_price {
                  value
                }
                regular_price {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

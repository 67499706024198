import { computed, reactive } from '@nuxtjs/composition-api';

const state = reactive({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  filterSidebarCategoryId: null,
  loginAndRegisterModalOpen: '',
  menuIsOpen: false,
  lockBodyScroll: false,
  showChangeCountry: false,
});

const useUiState = () => {
  const showChangeCountry = computed(() => state.showChangeCountry);
  const toggleShowChangeCountry = () => {
    state.showChangeCountry = !state.showChangeCountry;
  };

  const menuIsOpen = computed(() => state.menuIsOpen);
  const toggleMenuIsOpen = (value?: boolean) => {
    if (value !== undefined) {
      state.menuIsOpen = value;
      return;
    }
    state.menuIsOpen = !state.menuIsOpen;
  };

  const lockBodyScroll = computed(() => state.lockBodyScroll);
  const toggleLockBodyScroll = (value?: boolean) => {
    if (value !== undefined) {
      state.lockBodyScroll = value;
      return;
    }
    state.lockBodyScroll = !state.lockBodyScroll;
  };

  const isMobileMenuOpen = computed(() => state.isMobileMenuOpen);
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen);
  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);
  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const loginAndRegisterModalOpen = computed(() => state.loginAndRegisterModalOpen);
  const toggleLoginAndRegisterModal = (modal = '') => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.loginAndRegisterModalOpen = state.loginAndRegisterModalOpen === '' && modal === '' ? 'login' : modal;
  };

  const isNewsletterModalOpen = computed(() => state.isNewsletterModalOpen);
  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const isCategoryGridView = computed(() => state.isCategoryGridView);

  const isWishlistGridView = computed(() => state.isWishlistGridView);

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const filterSidebarCategoryId = computed(() => state.filterSidebarCategoryId);
  const isFilterSidebarOpen = computed(() => state.isFilterSidebarOpen);
  const toggleFilterSidebar = (categoryId: string = null) => {
    state.filterSidebarCategoryId = categoryId;
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  return {
    isCartSidebarOpen,
    isWishlistSidebarOpen,
    isNewsletterModalOpen,
    isCategoryGridView,
    isWishlistGridView,
    isFilterSidebarOpen,
    isMobileMenuOpen,
    loginAndRegisterModalOpen,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleMobileMenu,
    toggleLoginAndRegisterModal,
    filterSidebarCategoryId,
    menuIsOpen,
    toggleMenuIsOpen,
    lockBodyScroll,
    toggleLockBodyScroll,
    showChangeCountry,
    toggleShowChangeCountry,
  };
};

export default useUiState;

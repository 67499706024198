import { ref, Ref, useContext } from '@nuxtjs/composition-api';
import { ReCaptchaInstance } from '@nuxtjs/recaptcha';

type RecaptchaConfigType = {
  isEnabled: boolean;
};

type UseRecaptchaType = {
  isEnabled: Ref<boolean>;
  $recaptcha: ReCaptchaInstance;
};

const useRecaptcha = (): UseRecaptchaType => {
  const { $recaptcha, $config } = useContext();
  return {
    isEnabled: ref((typeof $recaptcha !== 'undefined' && !!($config?.recaptcha as RecaptchaConfigType)?.isEnabled) || false),
    $recaptcha,
  };
};

export default useRecaptcha;

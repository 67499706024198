import dataManipulation from '~/helpers/dataManipulation';

import { CREATE_WISHLIST_GQL } from './createWishlist';
import { LOAD_WISHLIST_GQL } from './loadWishlist';
import { ITEMS_IN_WISHLIST_GQL } from './itemsInWishlist';
import { WISHLIST_ITEMS_COUNT_GQL } from './wishlistItemsCount';
import { ADD_ITEM_TO_WISHLIST_GQL } from './addItemToWishlist';
import { REMOVE_ITEM_FROM_WISHLIST_GQL } from './removeItemFromWishlist';

const { getGqlStringFromDocumentNode } = dataManipulation();

export const LOAD_WISHLIST = getGqlStringFromDocumentNode(LOAD_WISHLIST_GQL);
export const CREATE_WISHLIST = getGqlStringFromDocumentNode(CREATE_WISHLIST_GQL);
export const ITEMS_IN_WISHLIST = getGqlStringFromDocumentNode(ITEMS_IN_WISHLIST_GQL);
export const WISHLIST_ITEMS_COUNT = getGqlStringFromDocumentNode(WISHLIST_ITEMS_COUNT_GQL);
export const ADD_ITEM_TO_WISHLIST = getGqlStringFromDocumentNode(ADD_ITEM_TO_WISHLIST_GQL);
export const REMOVE_ITEM_FROM_WISHLIST = getGqlStringFromDocumentNode(REMOVE_ITEM_FROM_WISHLIST_GQL);

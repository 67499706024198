import gql from 'graphql-tag';

export const ITEMS_IN_WISHLIST_GQL = gql`
  query ItemsInWishlist($wishlistUid: ID, $items: [String]) {
    itemsInWishlist(wishlistUid: $wishlistUid, items: $items) {
      product_id
      wishlist_ids {
        item_uid
        wishlist_uid
      }
    }
  }
`;

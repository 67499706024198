/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CustomerUpdateParameters } from '@gemini-vsf/api-client';

export const generateUserData = (userData): CustomerUpdateParameters => {
  const baseData = {
    email: userData.email,
    firstname: userData.firstName || userData.firstname,
    lastname: userData.lastName || userData.lastname,
  } as CustomerUpdateParameters;

  if (Object.prototype.hasOwnProperty.call(userData, 'is_subscribed')) {
    baseData.is_subscribed = userData.is_subscribed;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'dateOfBirth') || Object.prototype.hasOwnProperty.call(userData, 'date_of_birth')) {
    baseData.date_of_birth = userData.dateOfBirth || userData.date_of_birth;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'gender')) {
    baseData.gender = userData.gender;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'password')) {
    baseData.password = userData.password;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'phone_number')) {
    baseData.phone_number = userData.phone_number;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'taxCode') || Object.prototype.hasOwnProperty.call(userData, 'tax_code')) {
    baseData.tax_code = userData.taxCode || userData.tax_code;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'certifiedEmail') || Object.prototype.hasOwnProperty.call(userData, 'certified_email')) {
    baseData.certified_email = userData.certifiedEmail || userData.certified_email;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'sdi_code')) {
    baseData.sdi_code = userData.sdi_code;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'consents')) {
    baseData.consents = userData.consents;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'company_name')) {
    baseData.company_name = userData.company_name;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'fiscal_code')) {
    baseData.fiscal_code = userData.fiscal_code;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'aggregation_id')) {
    baseData.aggregation_id = userData.aggregation_id;
  }

  return baseData;
};

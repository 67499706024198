/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { sharedRef } from '@vue-storefront/core';
import { useGeminiApi } from '~/composables';
import { computed, useContext } from '@nuxtjs/composition-api';

const useTranslation = (translationsComposableKey = 'translations', attributeTranslationsComposableKey = 'attributeTranslations') => {
  const translations = sharedRef({}, translationsComposableKey);
  const attributeTranslations = sharedRef(null, attributeTranslationsComposableKey);

  const {
    $config: {
      payloadComponents: { translations: translationsReferences },
    },
    route: {
      value: { path },
    },
  } = useContext();
  const currentLocale = computed(() => path.split('/')[1] || 'it');

  const { getPayloadData } = useGeminiApi();

  const loadTranslations = async () => {
    try {
      const data = await getPayloadData(
        translationsReferences.translationsCollection as string,
        translationsReferences.translationsCollectionId as string
      );
      const translationsArray = data?.translations || [];
      const translationsObject = translationsArray.reduce((acc, { key, translation }) => {
        acc[key] = translation;
        return acc;
      }, {});
      translations.value = translationsObject;
    } catch (error) {
      // Log an error message if the translations block could not be loaded
      console.error(error);
      console.error('Could not get translations');
    }
  };

  const loadAttributeTranslations = async () => {
    try {
      const data = await getPayloadData(
        translationsReferences.attributeTranslationsCollection as string,
        translationsReferences.attributeTranslationsId as string
      );
      const translationsArray = data?.translations || [];
      const translationsObject = translationsArray.reduce((acc, { key, translation }) => {
        acc[key] = translation;
        return acc;
      }, {});
      attributeTranslations.value = translationsObject;
    } catch (error) {
      // Log an error message if the translations block could not be loaded
      console.error(error);
      console.error('Could not get attribute translations');
    }
  };

  const loadAllTranslations = async () => {
    await Promise.all([loadTranslations(), loadAttributeTranslations()]);
  };

  // Define a function called $gt that returns the translation for a given key
  // If no locale is provided, the current locale parsed from the current path is used
  const $gt = (stringToTranslate: string): string => translations?.value?.[stringToTranslate] || stringToTranslate;
  const $gat = (stringToTranslate: string): string => attributeTranslations?.value?.[stringToTranslate] || stringToTranslate;

  return {
    loadTranslations,
    translations,
    $gt,
    currentLocale,
    loadAttributeTranslations,
    $gat,
    attributeTranslations,
    loadAllTranslations,
  };
};

// Export the useTranslation function as the default export of this module
export default useTranslation;

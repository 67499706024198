/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloError } from '@apollo/client/errors';
import { UiNotification } from '~/composables/useUiNotification';

const APOLLO_ERRORS = {
  503: {
    'upstream connect error or disconnect/reset before headers. reset reason: connection termination':
      'Something went wrong, please refresh the page.',
  },
};

const logAllApolloNetworkErrors = (error: Record<string, any>) => {
  if (!Array.isArray(error?.result?.errors)) return;
  error.result.errors.forEach((e: Record<string, any>) => {
    if (typeof e?.message === 'string') {
      console.error(e.message);
    }
  });
};

export const handleError = <T extends Error>(error: T, initiator: string, sendNotification: (notification: UiNotification) => void) => {
  console.error(`useWishlist - ${initiator} - ${error}`);
  if (error instanceof ApolloError && error.networkError && 'statusCode' in error.networkError && 'result' in error.networkError) {
    const { statusCode, result } = error.networkError;
    if (typeof result === 'object' && result !== null) {
      logAllApolloNetworkErrors(result);
    }
    sendNotification({
      id: Symbol('wishlist_apollo_error'),
      message: APOLLO_ERRORS?.[statusCode]?.[result] || 'Something went wrong, please refresh the page.',
      type: 'danger',
      icon: 'error',
      persist: true,
      title: 'Success',
    });
    return;
  }
  sendNotification({
    id: Symbol('wishlist_generic_error'),
    message: 'Something went wrong, please refresh the page.',
    type: 'danger',
    icon: 'error',
    persist: true,
    title: 'Success',
  });
};

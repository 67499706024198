import { productGetters, Category, Product } from '@gemini-vsf/composables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getSeoCategorySlug(categories: Category[]): string {
  if (categories.length === 1) {
    return categories[0].url_path;
  }

  const seoCategories = categories.filter((category) => category.is_anchor);
  if (seoCategories.length === 0) {
    return categories[0].url_path;
  }

  return seoCategories[0].url_path;
}

const useUrlHelpers = () => {
  const buildProductUrl = (product: Product): string => {
    const productSlug = productGetters.getSlug(product);
    return `${productSlug}`;
  };

  return {
    buildProductUrl,
  };
};

export default useUrlHelpers;
